const ProjectCard = (props) => {
  const getTags = (t, k) => {
    switch (t) {
      case "game":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#ec9cff] text-[#78258C]`}
          >
            Game
          </span>
        );
      case "web":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#FFAB6E] text-[#B04A00]`}
          >
            Web
          </span>
        );
      case "mobile":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#d3ff94] text-[#4A8500]`}
          >
            Mobile
          </span>
        );
      case "java":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#ffa0a0] text-[#8E060E]`}
          >
            Java
          </span>
        );
      case "javascript":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#FFF27F] text-[#AB8500]`}
          >
            Javascript
          </span>
        );
      case "c":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#b0f0ff] text-[#005F94]`}
          >
            C/C++
          </span>
        );
      case "ui":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#CDCDCD] text-[#696969]`}
          >
            UI/UX
          </span>
        );
      case "go":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#AEF0FF] text-[#00929B]`}
          >
            Go
          </span>
        );
      case "kotlin":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#FFA5DB] text-[#9C0067]`}
          >
            Kotlin
          </span>
        );
      case "csharp":
        return (
          <span
            key={k}
            className={`text-[0.6rem] py-1 px-3 mr-1 rounded-full bg-[#a5ffb6] text-[#00793F]`}
          >
            C#
          </span>
        );
    }
  };

  return (
    <a
      href={props.rlink ? props.rlink : "#projects"}
      target={props.rlink ? "_blank" : "_self"}
    >
      <div className="transition-all group bg-white rounded-md w-[20rem] h-[7rem] border border-[#eaeaea] hover:border-[#c4c4c4] drop-shadow-lg py-5 px-7 cursor-pointer">
        {props.ronline ? (
          <div className="absolute text-[0.6rem] top-[-0.6rem] right-[0.6rem] border border-[#eaeaea] flex justify-center items-center px-[0.6rem] py-1 drop-shadow-md bg-white rounded-full">
            <div className="relative">
              <div className="absolute top-0 animate-ping w-[0.5rem] h-[0.5rem] rounded-full bg-green-300 mr-1 z-2"></div>{" "}
              <div className="relative w-[0.5rem] h-[0.5rem] rounded-full bg-green-400 mr-1 z-4"></div>{" "}
            </div>
            Online
          </div>
        ) : (
          ""
        )}
        <div className="group-hover:font-semibold group-hover:underline text-[0.9rem]">
          {props.rname}
        </div>
        <div className="text-[0.7rem] text-[#7c7c7c] mb-2">
          {props.rdesc.length > 36
            ? props.rdesc.slice(0, 36) + "..."
            : props.rdesc}
        </div>
        <div>{props.rcat.map((v, k) => getTags(v, k))}</div>
      </div>
    </a>
  );
};

export default ProjectCard;
