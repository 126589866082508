import ProjectCard from "./ProjectCard";
import axios from "axios";
import { useEffect, useState } from "react";

const Projects = () => {
  const repo = [
    {
      name: "Sans Battle Clone",
      desc: "Sans boss battle from undertale cloned using javascript",
      cat: ["game", "javascript"],
      online: true,
      link: "https://sans-battle-clone.vercel.app/",
    },
    {
      name: "Rose of an Ooze Clone",
      desc: "Rose of an Ooze boss level from cuphead cloned using javascript",
      cat: ["game", "javascript"],
      online: true,
      link: "https://rose-of-an-ooze-clone.vercel.app",
    },
    {
      name: "Enter the Gungeon Clone",
      desc: "Simple Enter the Gungeon clone game built using Java",
      cat: ["game", "java"],
      online: false,
      link: "https://www.github.com/luisfrentzen/gungeon-clone",
    },
    {
      name: "CHollaRR",
      desc: "NLP based education counseling submitted as a hackathon entry",
      cat: ["web"],
      online: false,
      link: "https://www.github.com/luisfrentzen/starton-hackathon",
    },
    {
      name: "MST Clustering",
      desc: "Kruskal based clustering algorithm",
      cat: ["c"],
      online: false,
      link: "https://www.github.com/luisfrentzen/mst-clustering",
    },
    {
      name: "Learn Ezo",
      desc: "Android application to learn and practice Japanese language using fun games",
      cat: ["mobile", "kotlin"],
      online: false,
      link: "https://www.github.com/luisfrentzen/learn-ezo-tpa",
    },
    {
      name: "TPA: Game Programming",
      desc: "MMORPG Unity game project",
      cat: ["game", "csharp"],
      online: false,
      link: "",
    },
    {
      name: "TPA: Web Design and Programming",
      desc: "Youtube clone",
      cat: ["web", "javascript"],
      online: false,
      link: "https://www.github.com/luisfrentzen/tpa-web-backend-fs",
    },
    {
      name: "TPA: Desktop Application",
      desc: "Desktop application and design",
      cat: ["csharp"],
      online: false,
      link: "https://www.github.com/luisfrentzen/tpa-desktop-fs",
    },
    {
      name: "Security Management System",
      desc: "Security data management system",
      cat: ["web", "go"],
      online: false,
      link: "",
    },
  ];

  return (
    <div
      id="projects"
      className="relative w-full h-fit bg-white flex flex-col justify-center items-center"
    >
      <div className="w-full bottom-0 top-0 left-0 right-0 bg-[url('/src/assets/grid.png')] bg-repeat bg-[length:250px_250px] absolute opacity-10 z-5"></div>
      <div className="md:text-2xl text-xl w-[80%] h-[20rem] flex justify-center items-end align-center py-20">
        <p className="text-center">
          and many others as shown in my <b>Github</b> 🐵.
        </p>
      </div>
      <div className="w-full h-fit bg-white flex justify-center items-center">
        <div className="w-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 pb-12 mb-12">
          {repo
            .slice(0, window.innerWidth < 768 ? 4 : repo.length)
            .map((v, index) => (
              <ProjectCard
                key={index}
                rname={v.name}
                rdesc={v.desc}
                rcat={v.cat}
                ronline={v.online}
                rlink={v.link}
              />
            ))}
          <a href="https://www.github.com/luisfrentzen" target="_blank">
            <div className="relative group color-hue-light text-white rounded-md w-[20rem] h-[7rem] border border-[#eaeaea] font-semibold overflow-hidden drop-shadow-lg py-5 px-7 flex justify-center items-center cursor-pointer">
              <div className="absolute left-[-4rem] h-[10rem] transition-all duration-[500ms] group-hover:h-[30rem] group-hover:w-[30rem] w-[10rem] rounded-full bg-wblack/[0.35] mix-blend-overlay z-0"></div>
              <div className="absolute left-[-8rem] h-[10rem] transition-all duration-[1000ms] group-hover:h-[50rem] group-hover:w-[50rem] w-[10rem] rounded-full bg-wblack/[0.35] mix-blend-overlay z-0"></div>
              <div className="relative text-white z-2 select-none">
                View more
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
