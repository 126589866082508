import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useReducer,
} from "react";
import {
  Stage,
  Graphics,
  withFilters,
  Container,
  useTick,
} from "@inlet/react-pixi";
import { TypeAnimation } from "react-type-animation";
import { Ticker } from "pixi.js";
import { createNoise2D } from "simplex-noise";
import hsl from "hsl-to-hex";
import debounce from "debounce";
import { KawaseBlurFilter } from "@pixi/filter-kawase-blur";
import { AsciiFilter } from "@pixi/filter-ascii";

const Landing = () => {
  class ColorPalette {
    constructor() {
      this.setColors();
      this.setCustomProperties();
    }

    setColors() {
      this.hue = ~~random(220, 360);
      this.complimentaryHue1 = this.hue + 30;
      this.complimentaryHue2 = this.hue + 60;
      this.saturation = 95;
      this.lightness = 50;

      this.baseColor = hsl(this.hue, this.saturation, this.lightness);
      this.complimentaryColor1 = hsl(
        this.complimentaryHue1,
        this.saturation,
        this.lightness
      );
      this.complimentaryColor2 = hsl(
        this.complimentaryHue2,
        this.saturation,
        this.lightness
      );

      this.colorChoices = [
        this.baseColor,
        this.complimentaryColor1,
        this.complimentaryColor2,
      ];
    }

    randomColor() {
      return this.colorChoices[~~random(0, this.colorChoices.length)].replace(
        "#",
        "0x"
      );
    }

    setCustomProperties() {
      document.documentElement.style.setProperty("--hue", this.hue);
      document.documentElement.style.setProperty(
        "--hue-complimentary1",
        this.complimentaryHue1
      );
      document.documentElement.style.setProperty(
        "--hue-complimentary2",
        this.complimentaryHue2
      );
    }
  }

  function random(min, max) {
    return Math.random() * (max - min) + min;
  }

  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  function getBounds() {
    const maxDist =
      window.innerWidth < 1024 ? window.innerWidth / 5 : window.innerWidth / 4;
    const originX =
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 1.3;
    const originY =
      window.innerWidth < 1024
        ? window.innerHeight / 1.3
        : window.innerHeight / 1.775;

    return {
      x: {
        min: originX - maxDist,
        max: originX + maxDist,
      },
      y: {
        min: originY - maxDist,
        max: originY + maxDist,
      },
    };
  }

  const orbs = useRef([]);

  let pallete = new ColorPalette();
  const simplex = useMemo(() => new createNoise2D(), []);
  const ticker = useMemo(() => new Ticker());
  ticker.autoStart = false;
  const containerRef = useRef(null);

  const [bounds, setBounds] = useState(getBounds());
  const handleResize = debounce(() => {
    setBounds(getBounds());
  }, 250);

  useEffect(() => {
    ticker.start();
    console.log("testing");
    window.addEventListener("resize", handleResize);
    return () => {
      ticker.stop();
      window.removeEventListener("resize", handleResize);
    };
  });

  const reducer = (state, action) => {
    switch (action.type) {
      case "update":
        if (window.innerWidth < 1024) return { ...state };

        const xNoise = simplex(state.xoff, state.xoff);
        const yNoise = simplex(state.yoff, state.yoff);
        const scaleNoise = simplex(state.xoff, state.yoff);

        return {
          ...state,
          x: map(xNoise, -1, 1, bounds["x"].min, bounds["x"].max),
          y: map(yNoise, -1, 1, bounds["y"].min, bounds["y"].max),
          scale: map(scaleNoise, -1, 1, 0.5, 1),
          xoff: state.xoff + action.payload / 4000,
          yoff: state.yoff + action.payload / 4000,
        };
      case "colorUpdate":
        return {
          ...state,
          fill: pallete.randomColor(),
        };
      default:
        return state;
    }
  };

  const Orb = forwardRef((props, ref) => {
    const [state, dispatch] = useReducer(reducer, {
      x: random(bounds["x"].min, bounds["x"].max),
      y: random(bounds["y"].min, bounds["y"].max),
      radius: random(window.innerHeight / 8, window.innerHeight / 3),
      scale: 1,
      fill: pallete.randomColor(),
      xoff: random(0, 1000),
      yoff: random(0, 1000),
    });

    useImperativeHandle(ref, () => ({
      childUpdate: (delta) => {
        dispatch({ type: "update", payload: delta });
      },
      colorUpdate: () => {
        dispatch({ type: "colorUpdate" });
      },
    }));

    const render = useCallback(
      (g) => {
        g.beginFill(state.fill);
        g.drawCircle(0, 0, state.radius);
        g.endFill();
      },
      [state.fill, state.radius]
    );

    return (
      <Graphics
        draw={render}
        x={state.x}
        y={state.y}
        scale={state.scale}
        alpha={0.825}
      />
    );
  });

  const updateChild = useCallback((delta) => {
    orbs.current.forEach((ref) => {
      if (ref) {
        ref.childUpdate(delta);
      }
    });
  });

  ticker.add(updateChild);

  const options = { resizeTo: containerRef.current, backgroundAlpha: 0 };
  const Filters = withFilters(Container, {
    blur: KawaseBlurFilter,
    ascii: AsciiFilter,
  });

  const handleColor = () => {
    pallete = new ColorPalette();
    orbs.current.forEach((ref) => {
      if (ref) {
        ref.colorUpdate();
      }
    });
  };

  return (
    <div className="w-full h-screen overflow-hidden">
      <div className="w-full h-screen absolute overflow-hidden flex z-10">
        <div className="flex sm:justify-between justify-end items-center w-screen h-8 absolute py-12 px-8 md:px-24">
          <div
            className="text-[1.7rem] cursor-pointer transition-all select-none active:scale-[0.95] hover:animate-wiggle font-emoji"
            onClick={handleColor}
          >
            🐸
          </div>
          <a href="#contacts" className="sm:flex hidden">
            <div className="hover:scale-[103%] transition-all flex justify-content items-center text-[0.9rem] font-medium text-white rounded-full bg-wblack py-2 px-6 select-none cursor-pointer">
              Contact
            </div>
          </a>
        </div>
        <div className="w-3/4 h-screen flex justify-start box-border items-center">
          <div className="w-full px-8 md:px-24 flex flex-col pb-[3rem]">
            <div className="md:text-2xl text-lg text-wblack mb-6">
              <i>
                Hi i'm <b>Luis Frentzen</b>
              </i>{" "}
              🐢 —
            </div>
            <div className="md:text-5xl text-3xl text-wblack font-semibold leading-tight">
              an especially{" "}
              <TypeAnimation
                className="text-gradient font-[monospace] font-bold type-custom"
                sequence={[
                  "inquisitive",
                  9000,
                  "versatile",
                  9000,
                  "imaginative",
                  9000,
                  "resourceful",
                  9000,
                  "passionate",
                  9000,
                ]}
                speed={2}
                deletionSpeed={55}
                wrapper="span"
                cursor={false}
                repeat={Infinity}
              />
              {window.innerWidth < 500 ? <br /> : ""}
              engineer.
            </div>
          </div>
        </div>
        <div className="w-1/2 h-screen flex justify-center items-center">
          {/* <Editor /> */}
        </div>
      </div>
      <div className="w-full h-screen bg-[url('/src/assets/grid.png')] bg-repeat bg-[length:250px_250px] absolute opacity-10 z-5"></div>
      <div ref={containerRef} className="w-[110vw] h-[110vh] z-0">
        <Stage
          width={window.innerWidth + 200}
          height={window.innerHeight + 200}
          options={options}
        >
          <Filters blur={{ blur: 25, quality: 3 }} ascii={{ size: 6 }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Orb key={index} ref={(ref) => (orbs.current[index] = ref)} />
            ))}
          </Filters>
        </Stage>
      </div>
    </div>
  );
};

export default Landing;
