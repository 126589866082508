import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { createTheme } from "@uiw/codemirror-themes";
import { tags as t } from "@lezer/highlight";
import { javascript } from "@codemirror/lang-javascript";
import icon from "../assets/fs-icon.svg";
import {
  EditorView,
  GutterMarker,
  gutter,
  highlightActiveLine,
  highlightActiveLineGutter,
} from "@codemirror/view";

const myTheme = createTheme({
  theme: "light",
  settings: {
    background: "transparent",
    foreground: "#75baff",
    caret: "#5d00ff",
    selection: "#036dd626",
    selectionMatch: "#036dd626",
    lineHighlight: "#8a91991a",
    gutterBackground: "transparent",
    gutterForeground: "#8a919966",
  },
  styles: [
    { tag: t.comment, color: "#787b8099" },
    { tag: t.variableName, color: "#0080ff" },
    { tag: [t.string, t.special(t.brace)], color: "#5c6166" },
    { tag: t.number, color: "#5c6166" },
    { tag: t.bool, color: "#5c6166" },
    { tag: t.null, color: "#5c6166" },
    { tag: t.keyword, color: "#5c6166" },
    { tag: t.operator, color: "#5c6166" },
    { tag: t.className, color: "#5c6166" },
    { tag: t.definition(t.typeName), color: "#5c6166" },
    { tag: t.typeName, color: "#5c6166" },
    { tag: t.angleBracket, color: "#5c6166" },
    { tag: t.tagName, color: "#5c6166" },
    { tag: t.attributeName, color: "#5c6166" },
  ],
});

class NumberMarker extends GutterMarker {
  constructor(number) {
    super();
    this.number = number;
  }

  eq(other) {
    return this.number === other.number;
  }

  toDOM(_view) {
    return document.createTextNode(this.number);
  }
}

const formatNumber = (view, linenumber) => {
  return String(linenumber);
};

const lineNumbersExtension = gutter({
  class: "cm-lineNumbers md:w-14 w-5 flex",
  renderEmptyElements: false,

  lineMarker(view, line, others) {
    if (others.some((m) => m.toDOM)) return null;
    return new NumberMarker(
      formatNumber(view, view.state.doc.lineAt(line.from).number)
    );
  },
});

const Editor = (props) => {
  const onChange = React.useCallback((value, viewUpdate) => {
    props.onCodeChange(value);
  }, []);

  let code = `const sub = () => (
    <p>
      I <i>love</i> to tinker with various techs and tools 👽. Especially about graphics like the <b>PixiJS</b> canvas above or other exceptional tools like this <b>Codemirror</b> editor.
    </p>
  )`;

  return (
    <div className="md:w-[80%] lg:w-[50%] w-full h-[60%] bg-[#ffffff] overflow-hidden backdrop-blur-sm border-[#c6c6c6] border-[1px] md:rounded-md drop-shadow-lg">
      <div className="flex items-center lg:h-[10%] h-[7%] w-full text-wblack">
        <div className="relative flex items-center h-full w-fit border-r border-[#c6c6c6] md:text-sm text-[0.7rem] text-wblack cursor-pointer">
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className="md:h-4 md:w-4 h-3 w-3 md:ml-6 ml-4 md:mr-4 mr-2"
          >
            <style></style>
            <path
              d="m21.3 78h-14.5v-55.6h38.8v11.2h-24.3v11.2h22.5v11.1h-22.5zm50.4 0.9q-6.5 0-12.5-1.6-6-1.6-9.9-4.3l4.1-11.3q3.6 2.4 8.5 3.9 4.9 1.4 9.8 1.4 5.2 0 7.3-1.4 2.1-1.4 2.1-3.4 0-1.8-1.5-2.9-1.4-1.2-5.1-1.9l-9.2-2q-15-3.2-15-15.7 0-5.4 2.9-9.5 3-4.1 8.3-6.4 5.3-2.2 12.3-2.2 5.7 0 10.9 1.6 5.2 1.6 8.4 4.4l-4 10.5q-3-2.2-7-3.4-3.9-1.2-8.5-1.2-4.4 0-6.7 1.5-2.4 1.5-2.4 4 0 1.7 1.4 2.8 1.4 1.1 5.1 1.9l9.1 2q15.2 3.2 15.2 15.3 0 5.4-2.9 9.4-2.9 4.1-8.2 6.3-5.3 2.2-12.5 2.2z"
              className="fill-hue"
            />
          </svg>
          <p className="md:pr-24 pr-10">Portfolio.jsx</p>
          <div className="absolute bottom-0 w-[100%] h-[0.15rem] gradient"></div>
        </div>
        <div className="w-full h-full bg-[#f3f3f3] border-b border-b-[#c6c6c6]"></div>
      </div>
      <div className="pl-6 flex items-center lg:h-[5%] h-[4%] w-full bg-[#f3f3f3] border-b border-b-[#c6c6c6] text-[#c6c6c6] font-thin md:text-[0.85rem] text-[0.65rem]">
        <p>src &gt; components</p>
      </div>
      <CodeMirror
        className="w-full h-[85%] overflow-scroll lg:text-[1.1rem] md:text-[0.95rem] text-[0.85rem]"
        onChange={onChange}
        value={code}
        theme={myTheme}
        height="100%"
        maxHeight="100%"
        basicSetup={false}
        extensions={[
          javascript({ jsx: true }),
          EditorView.lineWrapping,
          lineNumbersExtension,
          highlightActiveLine(),
          highlightActiveLineGutter(),
          gutter({ class: "w-8" }),
        ]}
      />
    </div>
  );
};
export default Editor;
