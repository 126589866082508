import Editor from "./Editor";
import * as babel from "@babel/parser";
import React, { useCallback, useState } from "react";
import DOMPurify from "dompurify";
import { debounce } from "debounce";

const Playground = () => {
  const [view, setView] = useState(`<p>
  I <i>love</i> to tinker with various techs and tools 👽. Especially about graphics like the <b>PixiJS</b> canvas above or other exceptional tools like this <b>Codemirror</b> editor.
</p>`);
  const [error, setError] = useState("");

  const handleCodeChange = useCallback(
    debounce((code) => {
      let ast = null;
      try {
        ast = babel.parse(code, {
          sourceType: "module",
          attachComment: false,
          plugins: ["jsx"],
        });
      } catch (e) {
        setError(e.toString());
        return;
      }

      const body = ast.program.body;
      let elmnode = null;

      for (const b of body) {
        if (b.type === "VariableDeclaration") {
          for (const d of b.declarations) {
            if (
              d.id.name === "sub" &&
              d.init.type === "ArrowFunctionExpression"
            ) {
              elmnode = d.init.body;
            }
          }
        }
      }

      let parsed = "";
      if (elmnode) {
        parsed = code.slice(elmnode.start, elmnode.end);

        const sanitized = DOMPurify.sanitize(parsed, {
          ALLOWED_TAGS: ["p", "b", "i", "u"],
        });

        setView(sanitized);
        setError("");
      } else {
        if (view) {
          setError(
            "ParseError: Arrow function 'sub' not found. Please provide an arrow function named 'sub'."
          );
        } else {
          setError("FunError: Editor is empty. Try to write some texts.");
        }
      }
    }, 250),
    []
  );

  return (
    <div className="relative w-full h-screen overflow-hidden flex flex-col items-center justify-end text-wblack z-10 pb-4">
      <div className="h-[35%] lg:w-[60%] w-[80%] flex justify-center text-center items-center md:text-2xl md:text-lg z-10">
        {view && !error ? (
          <span
            className="max-w-[90%] max-h-[90%] overflow-hidden"
            dangerouslySetInnerHTML={{ __html: view }}
          />
        ) : (
          <div className="relative lg:w-[70%] w-full h-fit font-[monospace] rounded-md flex flex-col justify-center items-start lg:px-10 px-6 lg:py-6 py-3 bg-[#ff5e5e] text-white lg:text-base text-[0.85rem] leading-4">
            <b>Error: </b>
            <p className="text-left">{error}</p>
            <div className="absolute bg-[#dc3055] w-full h-[1rem] left-0 top-[-0.5rem] rounded-t-md"></div>
          </div>
        )}
      </div>
      <Editor onCodeChange={handleCodeChange} />
    </div>
  );
};

export default Playground;
