const Contact = () => {
  return (
    <div id="contacts" className="w-full h-[100vh]">
      <div className="box-border w-full lg:h-[75%] h-[85%] flex flex-col justify-center items-center">
        <div className="pt-24 w-[50%] h-fit text-wblack md:text-4xl text-2xl text-center mb-6">
          <div className="mb-2 text-4xl">🤠</div>
          <div>
            Let's <b>connect!</b>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <a href="https://www.linkedin.com/in/luisfrentzen/" target="_blank">
            <div className="group relative cursor-pointer md:w-16 w-12 md:h-16 h-12 rounded-full flex justify-center items-center bg-[#e1e1e1] md:m-4 m-2">
              <div className="z-0 w-0 h-0 absolute rounded-full transition-all group-hover:w-20 group-hover:h-20 color-comp"></div>

              <svg
                className="relative z-2 w-8 h-8 m-2 box-border pb-1"
                fill="#ffffff"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 310 310"
              >
                <g id="XMLID_801_">
                  <path
                    id="XMLID_802_"
                    d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z"
                  />
                  <path
                    id="XMLID_803_"
                    d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                  />
                  <path
                    id="XMLID_804_"
                    d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z"
                  />
                </g>
              </svg>
            </div>
          </a>

          <a href="mailto: luisfrentzen@gmail.com" target="_blank">
            <div className="group relative cursor-pointer md:w-16 w-12 md:h-16 h-12 rounded-full flex justify-center items-center bg-[#e1e1e1] md:m-4 m-2">
              <div className="z-0 w-0 h-0 absolute rounded-full transition-all group-hover:w-20 group-hover:h-20 color-hue"></div>
              <svg
                className="relative z-2 w-8 h-8 m-2"
                fill="#ffffff"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 210 210"
              >
                <path
                  d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
	c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
	S0,162.897,0,105z"
                />
              </svg>
            </div>
          </a>

          <a href="https://www.github.com/luisfrentzen" target="_blank">
            <div className="group relative cursor-pointer md:w-16 w-12 md:h-16 h-12 rounded-full flex justify-center items-center bg-[#e1e1e1] md:m-4 m-2">
              <div className="z-0 w-0 h-0 absolute rounded-full transition-all group-hover:w-20 group-hover:h-20 color-comp2"></div>
              <svg
                className="relative z-2 w-8 h-8 m-2"
                viewBox="0 0 1024 1024"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
                  transform="scale(64)"
                />
              </svg>
            </div>
          </a>

          <a href="https://www.instagram.com/luisfrenzent/" target="_blank">
            <div className="cursor-pointer relative md:w-16 w-12 md:h-16 h-12 rounded-full flex justify-center items-center bg-[#e1e1e1] md:m-4 m-2 group">
              <div className="z-0 w-0 h-0 absolute rounded-full transition-all group-hover:w-20 group-hover:h-20 color-comp3"></div>
              <svg
                className="relative w-8 h-8 m-2 z-2"
                fill="#ffffff"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 169.063 169.063"
              >
                <g>
                  <path
                    d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
		c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
		c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
		c17.455,0,31.656,14.201,31.656,31.655V122.407z"
                  />
                  <path
                    d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
		C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
		c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"
                  />
                  <path
                    d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
		c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
		C135.661,29.421,132.821,28.251,129.921,28.251z"
                  />
                </g>
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div className="w-full lg:h-[25%] h-[15%] flex flex-col items-center justify-end bg-[#f6f6f6]">
        <div className="lg:text-sm text-[0.65rem] text-[#7c7c7c] lg:mb-2 mb-1">
          ʕ·͡ᴥ·ʔ
        </div>
        <div className="lg:text-sm text-[0.65rem] text-[#7c7c7c] lg:mb-14 mb-8 font-sans">
          © 2022, Luis Frentzen.
        </div>
      </div>
    </div>
  );
};

export default Contact;
