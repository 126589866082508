import Contact from "./components/Contact";
import Landing from "./components/Landing";
import Playground from "./components/Playground";
import Projects from "./components/Projects";

const App = () => {
  return (
    <div className="overflow-x-hidden scroll-smooth font-base">
      <Landing />
      <Playground />
      <Projects />
      <Contact />
    </div>
  );
};

export default App;
